<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400" :class="{'secondBox-bottom':$route.path === '/question' || $route.path === '/protocol'}">
      <div class="logo">
        <!-- <img
          :src="require('@/page/bienestarricotecnologia/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">WANPAY FINANCIAL INVESTMENT COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Hồ sơ công ty</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Sản phẩm</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">Giúp đỡ</span>
          </router-link>
        </li>
        <!-- <li
          :class="{ 'active-link': $route.path === '/protocol' }"
          class="Li4"
        >
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Thỏa thuận quyền riêng tư</span>
          </router-link>
        </li> -->
        <li class="dropdown Li4">
          <!-- 添加下拉图标 -->
          <span class="dropdown-toggle routeText4">Thỏa thuận quyền riêng tư</span>
          <ul class="dropdown-menu">
            <li class="firstLi">
              <a href="https://wan.wanpaya.com/v9i6td0c5w/Tx3C5v7Pa.html" target="_blank">
                <div class="dropdown-menu-content">
                  <img src="@/page/wanpaycompany/components/img/app.png" alt="App Store" class="iconImg" />
                  App Store
                </div>
              </a>
            </li>
            <li>
              <a href="https://wan.wanpaya.com/v9i6td0c5w/T7b9Y1u3Paa.html" target="_blank">
                <div class="dropdown-menu-content">
                  <img src="@/page/wanpaycompany/components/img/play.png" alt="Google Play" class="iconImg" />
                  Google Play
                </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
  height:90px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 90px; */
  padding: 0 15px;
  background-color: #44B548;
  /* box-shadow: 0px 2px 28px rgba(50, 55, 71, 0.10); */
  /* box-shadow: 0px 3px 12px rgba(65, 64, 64, 0.1); */
  /* background-image: url("@/page/bienestarricotecnologia/components/img/NavBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
}

.secondBox-bottom{
  /* box-shadow: 0px 1.5px 0px rgba(54, 53, 56, 1); */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}

.title {
  width: 500px;
  font-size: 18px;
  font-family: DM Sans-Bold;
  font-weight: 600;
  color: #FFFFFF;
  /* line-height: 23px; */
  padding-left: 17px;
  /* text-transform: uppercase; */
  height: 24px;
  line-height: 24px;
  /* height: 90px;
  line-height: 90px; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #FFFFFF;
  /* line-height: 29px; */
  /* text-transform: uppercase; */
}



.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 4px;
  padding-right: 4px;
  padding: 6px;
  height: 39px;
  /* line-height: 90px; */
}
.Li1 {
  width: 140px;
}
.Li2 {
  width: 120px;
}
.Li3 {
  width: 110px;
}
.Li4 {
  width: 250px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}
.active-link {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
  /* border-radius: 40px; */
  /* border-radius: 9px; */
  border-radius: 8px;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;
  color: #44B548;
}
.title{
  /* border: 1px solid red; */
  /* padding-left: 50px; */
  width: 550px
}
.secondBox{
  padding: 0 90px 0 130px;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 10%;
  background-color: #fff;
  padding: 5px 0;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: 0px 6px 12px #ccc;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu li a {
  font-size: 15px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #000000;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* cursor: pointer; */
  height: 100%;
  margin: 0  24px;
}

.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  /* 设置倒三角形的颜色 */
  border-bottom: none;
  /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}

.firstLi {
  border-bottom: 1px solid rgb(212, 212, 212);
}

.iconImg {
  width: 18px;
  height: 18px;
  padding-left: -20px;
  margin: 0 auto;
  margin-right: 10px;
}
.dropdown-menu-content{
  display: flex;
  align-items: center;
}
</style>